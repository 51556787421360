export default [
  {
    path: '/LandingPageLoginOne',
    name: 'landingPageLoginOne',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageOne/login.vue'),
  },
  {
    path: '/LandingPageFirstStepOne',
    name: 'landingFirstStepOne',
    meta: {
      title: '申请资料',
    },
    component: () => import('@/pages/pageOne/stepOne.vue'),
  },
  {
    path: '/LandingPageSecondStepOne',
    name: 'landingSecondStepOne',
    meta: {
      title: '申请资料',
    },
    component: () => import('@/pages/pageOne/stepTwo.vue'),
  },
  {
    path: '/LandingPageResultOne',
    name: 'landingResultOne',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageOne/result.vue'),
  },
  {
    path: '/LandingPageLoadingOne',
    name: 'landingLoadingOne',
    component: () => import('@/pages/pageOne/loading.vue'),
    meta: {
      title: '额度检测',
    },
  },
  {
    path: '/LandingPageSimpleStepOne',
    name: 'landingSimpleStepOne',
    component: () => import('@/pages/pageOne/simpleLeaveInfo.vue'),
    meta: {
      title: '申请资料',
    },
  },
];
