export default [
  {
    path: '/LandingPageLoginTwo',
    name: 'landingPageLoginTwo',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageTwo/login.vue'),
  },
  {
    path: '/LandingPageLeaveInfoTwo',
    name: 'landingPageLeaveInfoTwo',
    meta: {
      title: '申请资料',
    },
    component: () => import('@/pages/pageTwo/leaveInfo.vue'),
  },
  {
    path: '/LandingPageResultTwo',
    name: 'landingResultTwo',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageTwo/result.vue'),
  },
  {
    path: '/LandingPageLoadingTwo',
    name: 'landingLoadingTwo',
    component: () => import('@/pages/pageTwo/loading.vue'),
    meta: {
      title: '额度检测',
    },
  },
  {
    path: '/LandingPageSimpleStepTwo',
    name: 'landingSimpleStepTwo',
    component: () => import('@/pages/pageTwo/simpleLeaveInfo.vue'),
    meta: {
      title: '申请资料',
    },
  },
];
