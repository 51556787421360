export default [
  {
    path: '/LandingPageLoginFive',
    name: 'landingPageLoginFive',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageFive/login.vue'),
  },
  {
    path: '/LandingPageFirstStepFive',
    name: 'landingFirstStepFive',
    meta: {
      title: '申请资料',
    },
    component: () => import('@/pages/pageFive/stepOne.vue'),
  },
  {
    path: '/LandingPageSecondStepFive',
    name: 'landingSecondStepFive',
    meta: {
      title: '申请资料',
    },
    component: () => import('@/pages/pageFive/stepTwo.vue'),
  },
  {
    path: '/LandingPageResultFive',
    name: 'landingResultFive',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageFive/result.vue'),
  },
  {
    path: '/LandingPageLoadingFive',
    name: 'landingLoadingFive',
    component: () => import('@/pages/pageFive/loading.vue'),
    meta: {
      title: '额度检测',
    },
  },
  {
    path: '/LandingPageSimpleStepFive',
    name: 'landingSimpleStepFive',
    component: () => import('@/pages/pageFive/simpleLeaveInfo.vue'),
    meta: {
      title: '申请资料',
    },
  },
];
