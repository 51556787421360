import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { pageRouter } from '@/router/routerList/pageRouter';
import { storage } from '@/utils/storage';
import { useUserStore } from '@/store/modules/user';
import { ossPath } from '@/utils/http/axiosKeyConfig';
import axios from '@/utils/http/axios';
import { AgreementResultData, ipAddr, userAgreementResult } from '@/api/login';

const routes: Array<RouteRecordRaw> = [
  ...pageRouter,
  // 替代vue2中的'*'通配符路径
  { path: '/:pathMatch(.*)*', redirect: '/404' },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(), // hash 模式则使用 createWebHashHistory()
  routes,
});

router.afterEach((to, from) => {
  /* 路由发生变化修改页面 title */
  if (to?.meta?.title) document.title = String(to.meta.title);
  if (to.path === '/') {
    router.go(-1);
  }
});
router.beforeEach(async (to, from, next) => {
  if (to?.path?.indexOf('/LandingPageLogin') > -1 && to?.path?.indexOf('/LandingPageToken') === -1)
    storage.setItem('localUrl', to.path, null, 2);
  const query = to.query;
  for (const i in query) {
    saveRouterValue(i, <string>query[i]);
  }

  const {
    setAxiosBaseURL,
    axiosBaseURL,
    setCity,
    setAgreementURL,
    agreementURLArr,
    setWapHomeUrl,
    wapHomeUrl,
    urlMap,
    setUrlMap,
    setBodyCode,
    ossURL,
    setOssURL,
    bodyCode,
  } = useUserStore();

  // 获取公司主体
  // if (isEmpty(urlMap)) {
  //   const res: any = await axios.get(ossPath + '0');
  //   if (res?.data) {
  //     await setUrlMap(res.data);
  //     const host = window.location.origin;
  //     let companyUrl = res.data.default;
  //     if (res.data[host]) companyUrl = res.data[host];
  //     setBodyCode(companyUrl);
  //
  //     if (!ossURL) {
  //       const resp: any = await axios.get(ossPath + companyUrl + '/20');
  //       if (resp?.data?.url) setOssURL(resp.data.url);
  //     }
  //
  //     // 获取后台的动态接口域名
  //     if (!axiosBaseURL) {
  //       const res: any = await axios.get(ossPath + companyUrl + '/40');
  //       if (res?.data?.url) setAxiosBaseURL(res.data.url);
  //     }
  //
  // 获取wap端访问域名
  if (wapHomeUrl === '') {
    axios.get(ossPath + bodyCode + '/60').then((res) => {
      if (res?.data?.url) setWapHomeUrl(res?.data?.url);
    });
  }
  //   }
  // }

  if (!/404/.test(window.location.href)) {
    if (storage.getItem('localCity', 2) === null) {
      ipAddr().then((res) => {
        if (!res) return;
        storage.setItem('localCity', res.cityName, null, 2);
        if (storage.getItem('cityChoose', 2) === null) {
          storage.setItem('cityChoose', res.cityName, null, 2);
        }
        setCity(res.cityName);
      });
    }
  }

  if (!agreementURLArr) {
    userAgreementResult({ packageType: 10000 }).then((res: AgreementResultData) => {
      if (res) setAgreementURL(res);
    });
  }

  next();
});
function saveRouterValue(name: string, value: string) {
  if (name) {
    if (name === 'hxqbToken') {
      name = 'token';
    }
    if (name === 'token') {
      storage.setItem(name, value, null, 1);
    } else {
      storage.setItem(name, value, null, 2);
    }
  }
}

export default router;
