export const storage = {
  /**
   * localStorage 和  sessionStorage 相关的业务处理
   * @param type === 1 localStorage  默认值  type === 2 sessionStorage
   */
  getItem<T = string>(key: string, type = 1): T | null {
    const item = type === 2 ? sessionStorage.getItem(key) : localStorage.getItem(key);
    if (item) {
      const { value, expires } = JSON.parse(item);
      if (!expires || expires >= Date.now()) {
        return value;
      } else {
        this.removeItem(key);
        return null;
      }
    }
    return null;
  },

  /**
   * @param key
   * @param value
   * @param maxAge 相对缓存时间，单位s，默认1day，转换为expires(绝对时间)存储，传null不过期
   * @param type === 1 localStorage  默认值  type === 2 sessionStorage
   */
  setItem<T>(key: string, value: T, maxAge: number | null = 60 * 60 * 24 * 1, type = 1) {
    this.removeItem(key, type);
    const expires = maxAge && Date.now() + maxAge * 1000;
    if (type === 2) {
      sessionStorage.setItem(key, JSON.stringify({ value, expires }));
    } else {
      localStorage.setItem(key, JSON.stringify({ value, expires }));
    }
  },

  removeItem(key: string, type = 1) {
    type === 2 ? sessionStorage.removeItem(key) : localStorage.removeItem(key);
  },

  clear(type = 1) {
    type === 2 ? sessionStorage.clear() : localStorage.clear();
  },
};
