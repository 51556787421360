import http from '@/utils/http';

interface Point {
  countType: string;
  code: string;
  param?: {
    source?: string;
    productId?: number;
    productIds?: string;
    operateId?: number;
  };
}

// 埋点
export const doPoint = (data: Point, step?: number): Promise<any> => {
  if (
    (!step || step === 1) &&
    data.countType === 'web_page' &&
    (data.code === 'refereeOnlineLoan' || data.code === 'refereeApiLoan')
  ) {
    data.param = {
      ...data.param,
      ...{ source: 'web_remain_referee' },
    };
  }
  return http.post<any>(`/bury/count.e`, data);
};

interface Agreement {
  agreementId: number;
  loanProductId?: number;
}
// 埋点
export const signAgreement = (params: Agreement): Promise<any> =>
  http.get<any>(`/customer/signAgreementNew`, params);

// e签宝电子签章
export const signature = (params: {
  loanProductId: number;
  agreementName: string;
  agreementId: number;
  otherAgencyName?: string;
  templateNo?: string;
}): Promise<any> => http.post<any>(`/customer/signatureNew`, params);
