let SIGNKEY = '';
let ossPath = '';
let baseUrl = '';
let ossImgPath = '';

switch (import.meta.env.VITE_USER_NODE_ENV) {
  case 'pro':
    baseUrl = 'https://gw.xiaoyiqb.cn';
    ossPath = 'https://hxqb-pro.oss-cn-chengdu.aliyuncs.com/open/domain/';
    ossImgPath = 'https://oss.xiaoyiqb.cn';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  case 'test':
    baseUrl = 'https://test-gw.xiaoyiqb.cn';
    ossPath = 'https://hxqb-test.oss-cn-chengdu.aliyuncs.com/test/open/domain/';
    ossImgPath = 'https://test-oss.xiaoyiqb.cn';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  default:
    baseUrl = 'https://test-gw.xiaoyiqb.cn';
    // baseUrl = 'http://192.168.110.120:11000/';
    ossPath = 'https://hxqb-pro.oss-cn-chengdu.aliyuncs.com/open/domain/';
    ossImgPath = 'https://oss.xiaoyiqb.cn';
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
}
export { SIGNKEY, ossPath, baseUrl, ossImgPath };
