import { Ref } from 'vue';

export function backModelMethods() {
  const goBackModalId = ref<number>(9);
  /*
   * 返回拦截弹窗白名单
   * 方便有些落地页私有首页返回拦截功能
   * */
  const isGlobalGoBackList = ref<string[]>(['/LandingPageLoginQJHSeventyTwo']);
  /**
   * 拦截弹框的方法
   * goBackModal: 需要显示的弹框节点
   * */
  function doGoBack(goBackModal: Ref) {
    if (goBackModal.value) {
      console.log(goBackModal.value);
      goBackModal.value.show = true;
    }
  }
  return {
    goBackModalId,
    isGlobalGoBackList,
    doGoBack,
  };
}
