export default [
    {
      path: '/LandingPageResultFour',
      name: 'landingResultFour',
      meta: {
        title: '荟信钱包',
      },
      component: () => import('@/pages/pageFour/result.vue'),
    },
    {
      path: '/LandingPageSimpleStepOne',
      name: 'landingSimpleStepOne',
      component: () => import('@/pages/pageOne/simpleLeaveInfo.vue'),
      meta: {
        title: '申请资料',
      },
    },
  ];