<template>
  <!--返回拦截弹框-->
  <div class="sureBack_wrap" v-if="modalShow">
    <img class="close" @click="close" src="@/assets/img/base/close_round.png" alt="" />
    <img class="img" @click="jumpOperate" :src="baseImgUrl.jointUrl(operateData!.logo)" alt="" />
  </div>
</template>

<script setup lang="ts">
  import { pageJump } from '@/hooks/useJump';
  import { OperateConfig } from '@/api/base';
  import { baseImgUrl, CountType, Product } from '@/utils/config';
  import { doPoint } from '@/api/point';

  const props = defineProps({
    operateData: Object as () => OperateConfig,
  });

  const { jump } = pageJump();
  const show = ref<boolean>(false);
  const modalShow = ref<boolean>(false);
  function close() {
    modalShow.value = false;
    show.value = false;
  }
  const jumpOperate = async () => {
    await doPoint({
      countType: CountType.operate,
      code: Product.click,
      param: { operateId: props.operateData!.id },
    });
    window.location.href = props.operateData!.jumpUrl;
  };

  watch(
    () => show.value,
    (value) => {
      modalShow.value = value;
      if (value)
        doPoint({
          countType: CountType.operate,
          code: Product.show,
          param: { operateId: props.operateData!.id },
        });
    }
  );
  function goPage() {
    jump();
  }
  defineExpose({
    show: show,
  });
</script>

<style scoped lang="less">
  @import '../../styles/variables';
  .sureBack_wrap {
    z-index: 2000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    .close {
      position: absolute;
      top: 40px;
      right: 20px;
      width: 24px;
      height: 24px;
    }
    .img {
      width: auto;
      max-width: 310px;
      height: auto;
    }
  }
</style>
