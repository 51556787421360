export default [
  {
    path: '/LandingPageLoginThree',
    name: 'landingPageLoginThree',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageThree/login.vue'),
  },
  {
    path: '/LandingPageFirstStepThree',
    name: 'landingFirstStepThree',
    meta: {
      title: '申请资料',
    },
    component: () => import('@/pages/pageThree/stepOne.vue'),
  },
  {
    path: '/LandingPageSecondStepThree',
    name: 'landingSecondStepThree',
    meta: {
      title: '申请资料',
    },
    component: () => import('@/pages/pageThree/stepTwo.vue'),
  },
  {
    path: '/LandingPageResultThree',
    name: 'landingResultThree',
    meta: {
      title: '荟信钱包',
    },
    component: () => import('@/pages/pageThree/result.vue'),
  },
  {
    path: '/LandingPageLoadingThree',
    name: 'landingLoadingThree',
    component: () => import('@/pages/pageThree/loading.vue'),
    meta: {
      title: '额度检测',
    },
  },
  {
    path: '/LandingPageSimpleStepThree',
    name: 'landingSimpleStepThree',
    component: () => import('@/pages/pageThree/simpleLeaveInfo.vue'),
    meta: {
      title: '申请资料',
    },
  },
];
