import http, { StrObjNum, StrObjStr } from '@/utils/http';
import { AgreementType } from '@/utils/constant';
/**
 * 获取验证码
 * **/
export const getSmsCode = (params: StrObjStr): Promise<StrObjStr> =>
  http.get<StrObjStr>(`/customer/login/sms.e`, params);
/**
 * 验证码登录,注册
 * */
export const codeRegister = (data: StrObjStr): Promise<StrObjStr> =>
  http.post<StrObjStr>(`/customer/login/login.e`, data);

/**根据ip获取当前城市*/
export const ipAddr = (): Promise<StrObjStr> => http.get<StrObjStr>(`/customer/ipAnalysis.e`);

/**用户是否需要留资**/
export const ifUserCapital = (): Promise<StrObjNum> => {
  return http.get<StrObjNum>('/product/isCityMatching');
};

/**修改城市**/
export const editCity = (data: { city: string; locationCity: string }): Promise<StrObjStr> => {
  return http.post<StrObjStr>('/customer/user/editCity', data);
};

export interface BaseInfo {
  idCard?: string;
  age?: string;
  name: string;
}
/**获取用户信息**/
export const getUserInfo = () => http.get('/customer/baseUserInfo');

/**域名配置查询**/
export const configQuery = (data: { type: number }) => http.get('/open/config/query.e', data);

export interface AgreementResultData {
  list: AgreementType[];
  urlParam: string;
}

export const userAgreementResult = (data: {
  packageType: number;
}): Promise<AgreementResultData> => {
  return http.get<AgreementResultData>(`/customer/userAgreementResultNew.e`, data);
};

// 阿里获取一键登录token
export const loginAccessToken = (): Promise<any> =>
  http.get<any>(`/customer/login/loginAccessToken.e`);
// 阿里一键登录
export const oneClickLogin = (data: { spToken: string }): Promise<any> =>
  http.post<any>(`/customer/login/oneClickLogin.e`, data);
