import { defineStore } from 'pinia';
import { AgreementResultData } from '@/api/login';
import { baseUrl, ossImgPath } from '@/utils/http/axiosKeyConfig';

export const useUserStore = defineStore('user', () => {
  const userCity = ref('');
  function setCity(city: string) {
    userCity.value = city;
  }

  const axiosBaseURL = ref(baseUrl);
  function setAxiosBaseURL(url: string) {
    axiosBaseURL.value = url;
  }

  const urlMap = ref<any>({});
  function setUrlMap(map: any) {
    urlMap.value = map;
  }
  const bodyCode = ref('hx');
  function setBodyCode(url: string) {
    bodyCode.value = url;
  }

  let ossURL = ossImgPath;
  function setOssURL(url: string) {
    ossURL = url;
  }

  const agreementURLArr = ref<AgreementResultData>();
  function setAgreementURL(url: AgreementResultData) {
    agreementURLArr.value = url;
  }

  const wapHomeUrl = ref<string>('');
  function setWapHomeUrl(url: string) {
    wapHomeUrl.value = url;
  }
  return {
    userCity,
    setCity,
    axiosBaseURL,
    setAxiosBaseURL,
    agreementURLArr,
    setAgreementURL,
    wapHomeUrl,
    setWapHomeUrl,
    urlMap,
    setUrlMap,
    bodyCode,
    setBodyCode,
    ossURL,
    setOssURL,
  };
});
