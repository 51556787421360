import { threeElementsCapital } from '@/api/retainCapital';
import http from '@/utils/http';

export interface BasePromise {
  code: number;
  data: any;
  message: string;
}

export interface LoginWithThreeElementsCapital {
  token: string;
  register: number;
  userDetail: UserDetail;
}

export interface UserDetail {
  id: number;
  uuid: string;
  name: string;
  idCard: string;
  userPhone: string;
  channelId: number;
  isAppActive: number;
  activeTime: string;
  webActive: number;
  webActiveTime: string;
  userType: number;
  createTime: string;
  certificationStatus: number;
  completeDataStatus: number;
  completeDataTime: string;
  city: string;
  locationCity: string;
  logOff: number;
  isCapital: number;
  isOcr: number;
  isPureEdition: number;
  certificationStep: number;
  idCardExpiration: number;
}

interface ChannelDetail {
  isAgreement: number;
}
export const channelDetail = (): Promise<ChannelDetail> =>
  http.get<ChannelDetail>(`/customer/channel/detail.e`);

export interface OperateConfig {
  id: number;
  name: string;
  type: number;
  location: number;
  sort: number;
  status: number;
  osDisplay: number;
  title: string;
  descriptor: string;
  jumpType: number;
  jumpUrl: string;
  isSplitJoint: number;
  logo: string;
  platformLimitType: number;
  platformStr: string;
  channelLimitType: number;
  channelListStr: string;
}

export const operateConfig = (data: { type: number; location: number }): Promise<OperateConfig[]> =>
  http.get<OperateConfig[]>(`/customer/operateConfig/query.e`, data);

interface ChannelCheck {
  status: number;
  registerStatus: number;
  channelCheck: number;
}
export const channelCheck = (data: { id: string; registerPageId: string }): Promise<ChannelCheck> =>
  http.get<ChannelCheck>(`/customer/channel/check.e`, data);
