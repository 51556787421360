import { defineStore } from 'pinia';

export const useMyLoading = defineStore('myLoading', () => {
  const requestLoading = ref(false); // 请求是否需要loading
  const requestLoadingText = ref(''); // loading的文字
  // 设置当前请求是否需要请求
  function setRequestLoading(status: boolean, val?: string) {
    requestLoading.value = status;
    if (val) {
      requestLoadingText.value = val;
    }
  }
  return {
    requestLoading,
    requestLoadingText,
    setRequestLoading,
  };
});
