import { RouteRecordRaw } from 'vue-router';
import pageOne from './one';
import pageTwo from './two';
import pageThree from './three';
import pageFour from './four';
import pageFive from './five';

export const commonRouter = [
  {
    path: '/creditApiApplySuccess',
    name: 'creditApiApplySuccess',
    component: () => import('@/components/pageResult/resultSuccess.vue'),
    meta: {
      title: '贷款方案',
    },
  },
  {
    path: '/creditApiApplyAgain',
    name: 'creditApiApplyAgain',
    component: () => import('@/components/pageResult/creditApiApplyAgain.vue'),
    meta: {
      title: '贷款方案',
    },
  },
  {
    path: '/noProduct',
    name: 'noProduct',
    component: () => import('@/components/pageResult/noProduct.vue'),
    meta: {
      title: '贷款方案',
    },
  },
  {
    path: '/applySuccess_phone',
    name: 'applySuccess_phone',
    component: () => import('@/components/pageResult/applySuccess_phone.vue'),
    meta: {
      title: '贷款方案',
    },
  },
  {
    path: '/applySuccess_wx',
    name: 'applySuccess_wx',
    component: () => import('@/components/pageResult/applySuccess_wx.vue'),
    meta: {
      title: '贷款方案',
    },
  },
  {
    path: '/applySuccess_phone_wx',
    name: 'applySuccess_phone_wx',
    component: () => import('@/components/pageResult/applySuccess_phone_wx.vue'),
    meta: {
      title: '贷款方案',
    },
  },
];

export const pageRouter: Array<RouteRecordRaw> = [...commonRouter, ...pageOne, ...pageTwo, ...pageThree, ...pageFour, ...pageFive];
