import baseImg from '@/assets/img/result/logo.png';
import { useUserStore } from '@/store/modules/user';

export const baseImgUrl = {
  baseImgUrl: '',
  jointUrl: (url: any) => {
    const { ossURL } = useUserStore();
    if (url == undefined || url == '' || url == null || url == 'null' || url == 'undefined') {
      return baseImg;
    } else if (url.indexOf('http') > -1) {
      return url;
    } else {
      return ossURL + url;
    }
  },
};

export const riFormType = {
  addressSelect: 'riAddressSelect',
  input: 'riInput',
  select: 'riSelect',
};

export const requestParameters = {
  // 请求参数
  request_SUCCESS: 0, // 请求成功状态
  request_FAIL: 1, // 请求失败状态
  request_TOKEN_ERROR: 401, // 登录失效
  request_SIGN_ERROR: 402, // 签名校验失败
  request_AUTH_ERROR: 403, // 访问受限
  request_RUN_EXCEPTION: -1, // 主动抛出异常（一般为参数校验失败，或流程校验不通过等）
  request_BLACK: -10, // 黑名单
  request_NOT_PERMISSION: -20, // 无权限访问
  request_TIMEOUT: -30, // 请求超时
  request_VISIT_OUT: -40, // 访问次数受限
  request_NOT_FOUND: 404, // 资源不存在
};

/*
 * 埋点信息
 * */
export const CountType = {
  web_page: 'web_page', //	web页面统计链接
  web_login_action: 'web_login_action', //	web登录相关事件统计链接
  action: 'action', // 事件统计链接
  product: 'product', // 产品相关统计链接
  operate: 'operate', // 运营位相关统计
};
export const WebPage = {
  loginPage: 'loginPage', //	登录页
  remainMeans1: 'remainMeans1', //	留资页1
  remainMeans2: 'remainMeans2', //	留资页2
  remainMeans3: 'remainMeans3', //	留资页3
  refereeProduct: 'refereeProduct', //	产品推荐页
  refereeApiLoan: 'refereeApiLoan', //	api推荐页
  refereeOnlineLoan: 'refereeOnlineLoan', //	网贷推荐页
  failReferee: 'failReferee', //	推荐失败页
  successReferee: 'successReferee', //	推荐成功页
  loanHome: 'loanHome', //	贷超首页
  secondaryRecommend: 'secondaryRecommend', //	二次推荐页面展示
};
export const WebLoginAction = {
  keyPhone: 'keyPhone', //	落地页手机号输入	手机号文本框键入
  checkCodeButton: 'checkCodeButton', //	落地页验证码获取按钮
  checkCode: 'checkCode', //	落地页验证码输入	验证码文本框键入
  loginButton: 'loginButton', //	落地页登录按钮
  tokenLoginSuccess: 'tokenLoginSuccess', //	静默登录成功
};
export const Action = {
  callback: 'callback', //	返回拦截弹窗	前端埋点
  callbackSure: 'callbackSure', //	返回拦截同意	前端埋点
  callbackGiveUp: 'callbackGiveup', //	返回拦截拒绝	前端埋点
  productApply: 'productApply', //	产品申请	前端埋点
  applySuccess: 'applySuccess', //	产品申请同意	前端埋点
  applySuccessGiveUp: 'applySuccessGiveup', //	产品申请拒绝	前端埋点
  secondaryRecommendGiveUp: 'secondaryRecommendGiveUp', //	二次推荐页面放弃申请
  secondaryRecommendApply: 'secondaryRecommendApply', //	二次推荐页面点击申请
};
export const Product = {
  show: 'show', // 产品展示
  click: 'click', // 点击
};

/*
 * TODO 需要跟换接口时需要修改的地方
 * */
// 安卓下载包
export const AndroidApkUrl = {
  base: 'https://oss.jieytui.com/apk/qrh_guanfang_1.0.apk',
};

/* 公用的年利率 、 1万利息 、 1千利息 */
export const commonInterestRate = {
  interest: 7.2,
  dayInterest: 0.02,
  rate: '2',
  rate_thousand: '0.2',
};
